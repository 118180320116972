<template>
  <div class="contents-area foreign-registers-contents">
    <div class="full-content">
      <div class="tariff-countries">
        <div class="container">
          <div class="h1-breadcrumbs">
            <h1 v-if="curCountry.cases">
              {{ translations("TariffsForDocsFrom") }}
              <span class="blue-text">{{ curCountry.cases.genitive }}</span>
            </h1>
            <Breadcrumbs
              v-if="curCountry.cases"
              :addItem="{ name: translations('Tariffs'), path: '/tariffs' }"
              :curItem="{ name: curCountry.text }"
            />
          </div>
          <router-link
            class="btn btn-blue"
            v-if="curCountry.cases"
            :to="'/country/' + curCountry.link_sef"
            >{{ translations("OrderDocumentsFrom") }}
            {{ curCountry.cases.genitive }}</router-link
          >
          <br />
          <br />
          <div class="product-area">
            <ProductList />
          </div>
          <br />
          <span
            v-if="curCountry.cases"
            @click="getPriceList(curCountry.id)"
            class="link price-list-link"
            >{{ translations("DownloadPriceList") }}
            {{ curCountry.cases.genitive }}</span
          >
          <br />
          <router-link class="link price-list-link" to="/tariffs">{{
            translations("BackToCountriesList")
          }}</router-link>
        </div>
      </div>
      <div class="dimmed">
        <div class="container">
          <PayOptions />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";
import ProductList from "@/components/egrul/search/ProductList";
import PayOptions from "@/components/egrul/PayOptions";

export default {
  name: "CountryTariffs",
  components: { ProductList, PayOptions },
  metaInfo() {
    return {
      title:
        this.translations("CountryTariffs") + " | " + this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.listSettings.foreignregSettings.meta_desc,
        },
        {
          name: "keywords",
          content: this.listSettings.foreignregSettings.meta_keywords,
        },
      ],
    };
  },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
    };
  },
  computed: {
    ...mapGetters([
      "productsLoaded",
      "isCountriesLoaded",
      "isCountriesLoading",
      "listLang",
      "listSettings",
      "curCountryId",
      "curCountry",
      "translations",
    ]),
  },
  methods: {
    ...mapActions([
      "fetchCountries",
      "setCurPageCountry",
      "fetchProducts",
      "refreshProducts",
      "fetchPriceList",
    ]),
    async getPriceList(countryId) {
      console.log("price for " + countryId);
      await this.fetchPriceList({
        lang: this.$cookies.get("user_language"),
        countryId,
      });
    },
  },
  async mounted() {
    await this.refreshProducts();
    if (!this.isCountriesLoaded && !this.isCountriesLoading) {
      console.log("fetching countries");
      await this.fetchCountries({});
      await this.setCurPageCountry(this.$route.path);
    } else {
      console.log("setting page country");
      await this.setCurPageCountry(this.$route.path);
    }
    await this.fetchProducts({
      lang: this.$cookies.get("user_language"),
      curCountry: this.curCountry.id,
    });
  },
};
</script>
